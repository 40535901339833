import React from "react";
import Layout from "../components/Layout";
import RightColumn from "../components/RightColumn";

export default class AboutUsPage extends React.Component {
  render() {
    return (
      <Layout sidebarImageUrl="/images/photo01.jpg" sidebarImageAlt="" metaTitle="About Us">
        <div id="mainContent">
          <p>
            WBA Analytical Laboratories has been performing microbiological and
            chemical analyses, research, and consultation for live production,
            feed and ingredients, slaughter, processing, further processing,
            product research and development, and food service operations for
            more than 20 years.&nbsp;{" "}
          </p>
          <p>
            The purpose of<strong> WBA Analytical Laboratories&reg; </strong>
            services is to provide exceptional laboratory, research,
            and&nbsp;technical services to our valued customers. This is
            achieved through adherence to all applicable requirements including
            those of our customers, regulatory agencies, and
            through commitment to:{" "}
          </p>
          <ul>
            <li>Customer satisfaction, </li>
            <li>Continuous improvement of our quality management system, </li>
            <li>Continuous improvement of our laboratory processes, and </li>
            <li>Our Team Members. </li>
          </ul>
          <div class="header-two">Confidentiality &amp; Data Management</div>
          <p>
            WBA Analytical Laboratories utilizes a state-of-the-art Laboratory
            Information Management System (LIMS) to keep your data protected and
            confidential. For your convenience, final reports and other
            information can be stored in a secure, password protected library
            available to you 24/7 over the internet. Within the laboratory,
            samples are identified by number to provide further confidentiality.
            In addition, all team members of WBA
            Analytical&nbsp;Laboratories&nbsp;are provided confidentiality and
            conflicts of interest training annually.
          </p>
          <div class="header-two">Customer Focus</div>
          <p>
            We are committed to meeting your business needs. We take customer
            satisfaction seriously and strive to provide the highest quality of
            service available. We can work with you to customize programs and
            reports to allow you to maximize the value of your testing programs.
          </p>
          <div class="header-two">Professional Affiliations</div>
          <ul>
            <li>International Association for Food Protection (IAFP) </li>
            <li>Arkansas Chapter of IAFP (founding member) </li>
            <li>American Meat Institute (AMI) </li>
            <li>National Chicken Council (NCC) </li>
            <li>
              American Association for Laboratory Accreditation (A2LA) Life
              Science Committee{" "}
            </li>
            <li>AOAC International </li>
            <li>American Society for Quality (ASQ) </li>
            <li>
              Arkansas Institute for Performance Excellence (Arkansas Baldridge
              Program){" "}
            </li>
            <li>American Chemical Society (ACS) </li>
            <li>Metabolomics Society </li>
            <li>American Association of Pharmaceutical Scientists </li>
            <li>American Society for Mass Spectrometry </li>
          </ul>
        </div>

        <RightColumn />
      </Layout>
    );
  }
}
